import { SET_IFRAME_RESOURCES } from '../actions/types';

export default function iframeResources(
	state = {
		default: '',
		selected: ''
	},
	{ type, payload }
) {
	switch (type) {
		case SET_IFRAME_RESOURCES:
			return payload;
		default:
			return state;
	}
}
