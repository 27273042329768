import { SET_VARIATIONS } from '../actions/types';
import { DEFAULT_VARIATION, DEFAULT_THEMEKIT } from '../constants/global';

export default function variations(
	state = {
		selected: {
			id: DEFAULT_VARIATION,
			themekit: DEFAULT_THEMEKIT
		},
		list: [],
		brandingColorsMap: {},
		brandingIconsMap: {}
	},
	{ type, payload }
) {
	switch (type) {
		case SET_VARIATIONS:
			return {
				...state,
				...payload
			};
		default:
			return state;
	}
}
