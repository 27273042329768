import { SET_BRANDING_CATEGORIES } from '../actions/types';

export default function brandingCategories(
	state = {
		list: [],
		selected: ''
	},
	{ type, payload }
) {
	switch (type) {
		case SET_BRANDING_CATEGORIES:
			return payload;
		default:
			return state;
	}
}
