import { SET_FOCUS_WITHIN } from '../actions/types';

export default function focusWithin(state = {}, { type, payload }) {
	switch (type) {
		case SET_FOCUS_WITHIN:
			return payload;
		default:
			return state;
	}
}
