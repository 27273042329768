import { ROOT_PATH } from '../constants/global';

export const encodePath = (...pathParts) => {
	return pathParts.map(encodeURIComponent).join('/');
};

export const transformDirName = (dirName = '') => {
	return dirName.toLowerCase().replace(/ /g, '-');
};

export const generateId = (prefix, category, page) =>
	`${prefix}category:${category}__page:${page}`;

export const generatePath = (...pathParts) => {
	let encodedParts = encodePath(...pathParts);
	encodedParts = encodedParts ? `/${encodedParts}` : '';
	const path = `${ROOT_PATH}${encodedParts}`;
	return `${path}/index.htm`;
};
