import React from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ERROR_ALERT_CONTENT, ERROR_ALERT_CLASSES } from '../constants/global';

const ErrorAlert = ({ content }) => {
	return (
		<Alert bsStyle="danger" className={ERROR_ALERT_CLASSES}>{` ${
			content || ERROR_ALERT_CONTENT
		}`}</Alert>
	);
};

ErrorAlert.propTypes = {
	content: PropTypes.string
};

export default ErrorAlert;
