import { SET_INTERSECTING } from '../actions/types';

export default function intersecting(state = {}, { type, payload }) {
	switch (type) {
		case SET_INTERSECTING:
			return payload;
		default:
			return state;
	}
}
