import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePrefs } from 'wsm-common-data';
import DeferredComponent from '../ws-scripts/components/DeferredComponent';
import Router from '../components/Router';
import ScrollToTop from '../components/ScrollToTop';
import Header from '../components/Header';
import ErrorAlert from '../components/ErrorAlert';
import Footer from '../components/Footer';
import NavigationModal from '../components/NavigationModal';
import { setBrandingDevices } from '../actions/brandingDevicesAction';
import Toast from '../components/Toast';
import { setScrollDirection } from '../actions/scrollDirectionAction';
import useScrollDirection from '../hooks/useScrollDirection';
import getActualDevice from '../utils/getActualDevice';
import generatePageConfig from '../utils/generatePageConfig';

import '../css/widget.css';
import '../sass/widget.scss';

const RouteElement = React.lazy(() => import('../components/RouteElement'));

const Widget = () => {
	const { widgetData, brandingDevices } = useSelector((state) => state);
	const { categoriesOrder } = usePrefs();
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const dispatch = useDispatch();
	const scrollDirection = useScrollDirection();

	useEffect(() => {
		dispatch(
			setBrandingDevices({
				...brandingDevices,
				selected: getActualDevice(window.innerWidth)
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // No dependencies included to only trigger effect once

	useEffect(() => {
		dispatch(setScrollDirection(scrollDirection));
	}, [scrollDirection, dispatch]);

	const { categoryConfig } = widgetData;
	const pageConfig = useMemo(() => {
		return categoryConfig
			? generatePageConfig(categoryConfig, categoriesOrder)
			: null;
	}, [categoryConfig, categoriesOrder]);

	if (!pageConfig) {
		return (
			<ErrorAlert content="Unable to load Design System documentation. Please try again soon." />
		);
	}

	const openModal = () => {
		const { body } = document;
		const navbar = document.querySelector('header .navbar');

		if (body && navbar) {
			// This logic fixes an issue on iOS
			// where the body element was able to scroll behind the fixed-position modal
			const { scrollY } = window;
			if (!navbar.classList.contains('position-static')) {
				// Prevent the transform animation before the fixed position is applied to body
				navbar.style.transform = 'translate3d(0, 100%, 0)';
			}
			body.style.top = `-${scrollY}px`;
			body.style.position = 'fixed';
		}

		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);

		const { body } = document;
		const navbar = document.querySelector('header .navbar');

		if (body && navbar) {
			// Unset logic within openModal() and
			// retain position within window
			const scrollY = body.style.top;

			navbar.style.transform = null;
			body.style.position = null;
			body.style.top = null;
			window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
		}
	};

	const getRouteElement = () => (
		<DeferredComponent>
			<RouteElement {...{ pageConfig }} />
		</DeferredComponent>
	);

	return (
		<Router>
			<ScrollToTop />
			<Header {...{ pageConfig, openModal }} />
			<div className="page">{getRouteElement()}</div>
			<Footer />
			<NavigationModal
				{...{ pageConfig, isOpen: modalIsOpen, closeModal }}
			/>
			<Toast />
		</Router>
	);
};

export default Widget;
