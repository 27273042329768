import { SET_TOAST } from '../actions/types';

export default function toast(
	state = { display: false, message: '' },
	{ type, payload }
) {
	switch (type) {
		case SET_TOAST:
			return payload;
		default:
			return state;
	}
}
