import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';
import { isBrowser } from 'ws-scripts/modules/environment';

const Router = ({ children }) =>
	isBrowser ? (
		<BrowserRouter>{children}</BrowserRouter>
	) : (
		<StaticRouter>{children}</StaticRouter>
	);

Router.propTypes = {
	children: PropTypes.element
};

export default Router;
