import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFocusWithin } from '../actions/focusWithinAction';

export const SCROLL_UP = 'up';
export const SCROLL_DOWN = 'down';
export const SCROLL_THRESHOLD = 48;

const useScrollDirection = () => {
	const dispatch = useDispatch();
	const { focusWithin } = useSelector((state) => state);
	const [scrollDir, setScrollDir] = useState(SCROLL_UP);

	useEffect(() => {
		let lastScrollY = window.pageYOffset;

		const updateScrollDir = () => {
			const scrollY = window.pageYOffset;

			// We haven't exceeded the threshold
			if (Math.abs(scrollY - lastScrollY) < SCROLL_THRESHOLD) {
				return;
			}

			if (focusWithin.navbar) {
				dispatch(setFocusWithin({ navbar: false }));
			}

			setScrollDir(scrollY > lastScrollY ? SCROLL_DOWN : SCROLL_UP);
			lastScrollY = scrollY > 0 ? scrollY : 0;
		};

		const onScroll = () => window.requestAnimationFrame(updateScrollDir);

		window.addEventListener('scroll', onScroll);

		return () => window.removeEventListener('scroll', onScroll);
	}, [focusWithin.navbar, dispatch]);

	return scrollDir;
};

export default useScrollDirection;
