import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { PanelGroup, Panel, Modal } from 'react-bootstrap';
import { setClassNames } from 'ddc-classnames-js';
import { setFocusWithin } from '../actions/focusWithinAction';
import { pageConfigProp } from '../constants/propTypes';
import { getBrandingPath } from '../utils/getBrandingPath';
import { BRANDING } from '../constants/global';

const NavigationModal = ({ pageConfig, isOpen, closeModal }) => {
	const dispatch = useDispatch();
	const { category } = useSelector((state) => state.pageInfo);
	const { variations, brandingDevices, brandingCategories } = useSelector(
		(state) => state
	);
	const [expandedCategory, setExpandedCategory] = useState('');

	useEffect(() => {
		const currentCategoryPage = pageConfig.find(
			(parentPage) => parentPage.categoryTitle === category
		);
		if (currentCategoryPage) {
			setExpandedCategory(currentCategoryPage.id);
		}
	}, [pageConfig, category, setExpandedCategory]);

	const handleSelect = (key) => {
		setExpandedCategory(key);
	};

	const getPath = (parentPage, childPath) => {
		return parentPage.categoryTitle === BRANDING
			? getBrandingPath(
					childPath,
					brandingDevices.selected,
					variations.selected.id,
					variations.selected.themekit,
					brandingCategories.selected
			  )
			: childPath;
	};

	const onExited = () => {
		dispatch(setFocusWithin({ navbar: false }));
	};

	return (
		<Modal
			show={isOpen}
			onHide={closeModal}
			onExited={onExited}
			id="ws-design-style-guide-modal"
			className="px-0"
			aria-labelledby="ws-design-style-guide-navigation-modal-title"
		>
			<Modal.Header className="d-flex justify-content-end border-bottom-0 p-0">
				<Modal.Title
					id="ws-design-style-guide-navigation-modal-title"
					className="sr-only"
				>
					Navigation
				</Modal.Title>
				<button type="button" aria-label="Close" onClick={closeModal}>
					<i aria-hidden="true" className="icon-remove" />
				</button>
			</Modal.Header>
			<Modal.Body>
				<nav aria-label="Primary">
					<PanelGroup
						accordion
						id="ws-design-style-guide-navigation-modal-accordion"
						className="px-5 pb-8 mb-0"
						activeKey={expandedCategory}
						onSelect={handleSelect}
					>
						{pageConfig.map((parentPage) => (
							<Panel
								eventKey={parentPage.id}
								key={parentPage.id}
								className="border-0 mt-0"
							>
								<Panel.Heading className="p-0">
									<Panel.Title
										toggle={parentPage.children.length > 1}
										className={setClassNames([
											'd-flex',
											expandedCategory ===
												parentPage.id && 'active'
										])}
									>
										{parentPage.children.length > 1 ? (
											parentPage.categoryTitle
										) : (
											<NavLink
												to={getPath(
													parentPage,
													parentPage.children[0].path
												)}
												onClick={closeModal}
											>
												{parentPage.categoryTitle}
											</NavLink>
										)}
									</Panel.Title>
								</Panel.Heading>
								{parentPage.children.length > 1 ? (
									<Panel.Body
										collapsible
										className="border-0 p-0"
									>
										<ul>
											{parentPage.children.map(
												({ id, path, pageTitle }) => (
													<li key={id}>
														<NavLink
															className={({
																isActive
															}) =>
																setClassNames([
																	...(isActive
																		? [
																				'font-weight-bold',
																				'text-body'
																		  ]
																		: [])
																])
															}
															to={getPath(
																parentPage,
																path
															)}
															onClick={closeModal}
														>
															{pageTitle}
														</NavLink>
													</li>
												)
											)}
										</ul>
									</Panel.Body>
								) : null}
							</Panel>
						))}
					</PanelGroup>
				</nav>
			</Modal.Body>
		</Modal>
	);
};

NavigationModal.propTypes = {
	pageConfig: pageConfigProp,
	isOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired
};

export default NavigationModal;
