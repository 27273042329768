export const SET_WIDGET_DATA = 'SET_WIDGET_DATA';
export const SET_PAGE_INFO = 'SET_PAGE_INFO';
export const SET_VARIATIONS = 'SET_VARIATIONS';
export const SET_IFRAME_RESOURCES = 'SET_IFRAME_RESOURCES';
export const SET_BRANDING_CATEGORIES = 'SET_BRANDING_CATEGORIES';
export const SET_BRANDING_DEVICES = 'SET_BRANDING_DEVICES';
export const SET_SCROLL_DIRECTION = 'SET_SCROLL_DIRECTION';
export const SET_INTERSECTING = 'SET_INTERSECTING';
export const SET_FOCUS_WITHIN = 'SET_FOCUS_WITHIN';
export const SET_TOAST = 'SET_TOAST';
export const SET_BRANDING_SPOT_ICONS = 'SET_BRANDING_SPOT_ICONS';
