export const reorderItems = (items = [], orderedItems = '') => {
	return [
		...new Set([
			...orderedItems
				.split(',')
				.map((orderedItem) => orderedItem.trim())
				.filter((orderedItem) => items.includes(orderedItem)),
			...items
		])
	];
};
