import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { setClassNames } from 'ddc-classnames-js';
import { setIntersecting } from '../actions/intersectingAction';
import { setFocusWithin } from '../actions/focusWithinAction';
import { pageConfigProp } from '../constants/propTypes';
import { generatePath } from '../utils/pageConfigUtils';
import { getBrandingPath } from '../utils/getBrandingPath';
import { BRANDING } from '../constants/global';

const Header = ({ pageConfig, openModal }) => {
	const ref = useRef();
	const dispatch = useDispatch();
	const {
		pageInfo,
		variations,
		brandingDevices,
		brandingCategories,
		intersecting,
		focusWithin,
		scrollDirection
	} = useSelector((state) => state);
	const { category } = pageInfo;

	useEffect(() => {
		const onIntersect = (entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					dispatch(setIntersecting({ header: true }));
				} else {
					dispatch(setIntersecting({ header: false }));
				}
			});
		};
		const observer = new IntersectionObserver(onIntersect);
		const header = ref.current;

		if (header) {
			observer.observe(header);
		}

		return () => observer.unobserve(header);
	}, [ref, dispatch]);

	const onFocus = () => dispatch(setFocusWithin({ navbar: true }));
	const onBlur = () => dispatch(setFocusWithin({ navbar: false }));

	return (
		<header
			{...{
				ref
			}}
		>
			<Navbar
				fluid
				aria-label="Primary"
				className={setClassNames([
					`scroll-${scrollDirection}`,
					intersecting.header && 'position-static',
					focusWithin.navbar && 'navbar-focus-within'
				])}
			>
				<Navbar.Header>
					<Navbar.Brand>
						<NavLink
							to={generatePath()}
							className="text-center"
							title="Go to Kinetic home"
							{...{
								onFocus,
								onBlur
							}}
						>
							<img
								alt="Kinetic"
								src="/static/assets/images/logos/kinetic/logo.svg"
							/>
						</NavLink>
					</Navbar.Brand>
					<button
						type="button"
						className="navbar-toggle d-block d-lg-none"
						onClick={openModal}
						aria-label="Open navigation"
						{...{
							onFocus,
							onBlur
						}}
					>
						<i aria-hidden="true" className="icon-menu" />
					</button>
				</Navbar.Header>
				<Nav>
					{pageConfig.map((parentPage) => (
						<React.Fragment key={parentPage.id}>
							<li>
								<NavLink
									className={setClassNames([
										'overflow-hidden',
										category === parentPage.categoryTitle &&
											'active-category'
									])}
									to={
										parentPage.categoryTitle === BRANDING
											? getBrandingPath(
													parentPage.children[0].path,
													brandingDevices.selected,
													variations.selected.id,
													variations.selected
														.themekit,
													brandingCategories.selected
											  )
											: parentPage.children[0].path
									}
								>
									{parentPage.categoryTitle}
								</NavLink>
							</li>
						</React.Fragment>
					))}
				</Nav>
			</Navbar>
		</header>
	);
};

Header.propTypes = {
	pageConfig: pageConfigProp,
	openModal: PropTypes.func.isRequired
};

export default Header;
