import { transformDirName, generateId, generatePath } from './pageConfigUtils';
import { reorderItems } from './reorderItems';

export default (categoryConfig, categoriesOrder) => {
	const categoryTitles = reorderItems(
		Object.keys(categoryConfig),
		categoriesOrder
	);

	return categoryTitles.map((c) => {
		const categoryTitle = c;
		const category = transformDirName(categoryTitle);
		let pageTitle = categoryConfig[categoryTitle][0];
		let page = transformDirName(pageTitle);
		let id = generateId(`${category}__`, category, page);

		return {
			path: generatePath(category),
			categoryTitle,
			category,
			pageTitle,
			page,
			id,
			children: categoryConfig[categoryTitle].map((p) => {
				pageTitle = p;
				page = transformDirName(pageTitle);
				id = generateId('', category, page);

				return {
					path: generatePath(category, page),
					categoryTitle,
					category,
					pageTitle,
					page,
					id
				};
			})
		};
	});
};
