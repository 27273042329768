import { SET_WIDGET_DATA } from '../actions/types';

export default function widgetData(state = {}, { type, payload }) {
	switch (type) {
		case SET_WIDGET_DATA:
			return payload;
		default:
			return state;
	}
}
