import { SET_SCROLL_DIRECTION } from '../actions/types';

export default function scrollDirection(state = '', { type, payload }) {
	switch (type) {
		case SET_SCROLL_DIRECTION:
			return payload;
		default:
			return state;
	}
}
