import { encodePath } from './pageConfigUtils';

export const getBrandingPath = (
	path,
	device,
	variation,
	themekit,
	brandingCategory
) => {
	const search = `?device=${device}&variation=${variation}&color=${themekit}&category=${encodePath(
		brandingCategory
	)}`;
	return `${path}${search}`;
};
