export const IS_DEV_ENV =
	process.env.NODE_ENV === 'development' &&
	process.env.DISPLAY_ENVIRONMENT_NAME === 'local';
export const HOME_PAGE_PATH = '/';
export const ROOT_PATH = '/style-guide';
export const INDEX_PAGE = 'index.htm';
export const widgetApiPath = '/api/widget/ws-design-style-guide';
export const iframeResourcesFetchPath = `${ROOT_PATH}/iframe-resources/${INDEX_PAGE}`;

export const DESKTOP_DEVICE = 'DESKTOP';
export const MOBILE_DEVICE = 'MOBILE';
export const DESKTOP = 'Desktop';
export const MOBILE = 'Mobile';
export const HI_RES = 'Hi-Res';

export const DEFAULT_VARIATION = 'v9_GLOBAL_0011_V2';
export const DEFAULT_THEMEKIT = 'BLUE_WHITE';

export const KINETIC_DESIGN_SYSTEM = 'Kinetic Design System';
export const BRANDING = 'Branding';
export const DESIGN_GUIDE = 'Design Guide';
export const DEVICE = 'device';
export const VARIATION = 'variation';
export const BRANDING_CATEGORY = 'branding-category';
export const THEMEKIT = 'themekit';
export const COLOR = 'color';
export const CATEGORY = 'category';
export const DESCRIPTION = 'description';
export const TAB = 'tab';
export const SYSTEM_ICONS = 'System Icons';
export const SPOT_ICONS = 'Spot Icons';
export const HOME = 'Home';
export const ERROR = 'Error';

// logging and new relic labels
export const NR_WIDGET_ERROR = 'wsDesignStyleGuideErrorMessage';
export const NR_PREFIX = 'DESIGN_STYLE_GUIDE';
export const BRANDING_COLOR_SECTION = 'BRANDING_COLOR_SECTION';
export const BRANDING_ICONS_SECTION = 'BRANDING_ICONS_SECTION';
export const BRANDING_SPOT_ICONS_SECTION = 'BRANDING_SPOT_ICONS_SECTION';
const DESIGN_CONFIG_STATIC = 'DESIGN_CONFIG_STATIC';
const CATEGORY_CONFIG = 'CATEGORY_CONFIG';
const PAGE_DOCS = 'PAGE_DOCS';
const IFRAME_RESOURCES = 'IFRAME_RESOURCES';
export const FETCH_ERROR = 'FETCH_ERROR';
const READ_ERROR = 'READ_ERROR';

export const DESIGN_CONFIG_STATIC_FETCH_ERROR = `${DESIGN_CONFIG_STATIC}/${FETCH_ERROR}`;
export const DESIGN_CONFIG_STATIC_READ_ERROR = `${DESIGN_CONFIG_STATIC}/${READ_ERROR}`;
export const CATEGORY_CONFIG_READ_ERROR = `${CATEGORY_CONFIG}/${READ_ERROR}`;
export const PAGE_DOCS_READ_ERROR = `${PAGE_DOCS}/${READ_ERROR}`;
export const IFRAME_RESOURCES_FETCH_ERROR = `${IFRAME_RESOURCES}/${FETCH_ERROR}`;

// resource selectors
export const FONT_SELECTOR = 'link[as="font"]';
export const STYLE_SELECTOR = 'style';
export const LINK_SELECTOR = 'link[rel="stylesheet"]';

export const ERROR_ALERT_CONTENT = 'Please try again soon.';
export const ERROR_ALERT_CLASSES = 'p-4 mb-4';

// headings
export const LINKABLE_HEADINGS = ['h2', 'h3', 'h4'];

export const errorAlert = (content = ERROR_ALERT_CONTENT) => {
	return `<div role="alert" className="alert alert-danger ${ERROR_ALERT_CLASSES}">${content}</div>`;
};

export const devices = [
	{ key: 1, id: MOBILE },
	{ key: 2, id: DESKTOP },
	{ key: 3, id: HI_RES }
];

export const videoFileExtensions = ['mp4', 'webm'];
