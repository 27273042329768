import React from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap';

const Footer = () => {
	return (
		<footer className="overflow-hidden">
			<Navbar aria-label="About this documentation" fluid>
				<Nav className="m-0">
					<NavItem
						href="https://ghe.coxautoinc.com/DDC-WebPlatform/ws-design-style-guide/"
						target="_blank"
						className="text-muted"
					>
						GitHub
					</NavItem>
					<NavItem
						id="feedback-button"
						href="#"
						role="button"
						className="text-muted"
					>
						Give Feedback
					</NavItem>
				</Nav>
			</Navbar>
		</footer>
	);
};

export default Footer;
