import { devices } from '../constants/global';

export default (innerWidth) => {
	let device;
	if (innerWidth < 768) {
		device = 0;
	} else if (innerWidth >= 768 && innerWidth <= 1200) {
		device = 1;
	} else {
		device = 2;
	}
	return devices[device].id;
};
