import { SET_BRANDING_DEVICES } from '../actions/types';
import { devices } from '../constants/global';

export default function brandingDevices(
	state = {
		list: devices,
		selected: ''
	},
	{ type, payload }
) {
	switch (type) {
		case SET_BRANDING_DEVICES:
			return payload;
		default:
			return state;
	}
}
