import { SET_BRANDING_SPOT_ICONS } from '../actions/types';

export default function brandingSpotIcons(state = [], { type, payload }) {
	switch (type) {
		case SET_BRANDING_SPOT_ICONS:
			return payload;
		default:
			return state;
	}
}
