import PropTypes from 'prop-types';

export const childPageProp = PropTypes.shape({
	path: PropTypes.string.isRequired,
	page: PropTypes.string.isRequired,
	pageTitle: PropTypes.string.isRequired,
	category: PropTypes.string.isRequired,
	categoryTitle: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired
});

export const parentPageProp = PropTypes.shape({
	path: PropTypes.string.isRequired,
	page: PropTypes.string.isRequired,
	pageTitle: PropTypes.string.isRequired,
	category: PropTypes.string.isRequired,
	categoryTitle: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	children: PropTypes.arrayOf(childPageProp)
}).isRequired;

export const pageConfigProp = PropTypes.arrayOf(parentPageProp).isRequired;
