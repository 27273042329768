import { SET_PAGE_INFO } from '../actions/types';

export default function pageInfo(
	state = {
		category: '',
		page: '',
		markdown: null
	},
	{ type, payload }
) {
	switch (type) {
		case SET_PAGE_INFO:
			return payload;
		default:
			return state;
	}
}
