import widgetData from './widgetDataReducer';
import pageInfo from './pageInfoReducer';
import variations from './variationsReducer';
import iframeResources from './iframeResourcesReducer';
import brandingCategories from './brandingCategoriesReducer';
import brandingDevices from './brandingDevicesReducer';
import scrollDirection from './scrollDirectionReducer';
import intersecting from './intersectingReducer';
import focusWithin from './focusWithinReducer';
import toast from './toastReducer';
import brandingSpotIcons from './brandingSpotIconsReducer';

const root = {
	widgetData,
	pageInfo,
	variations,
	iframeResources,
	brandingCategories,
	brandingDevices,
	scrollDirection,
	intersecting,
	focusWithin,
	toast,
	brandingSpotIcons
};

export default root;
