import React from 'react';
import { useSelector } from 'react-redux';
import { setClassNames } from 'ddc-classnames-js';

const Toast = () => {
	const toast = useSelector((state) => state.toast);

	return (
		<div
			className={setClassNames([
				'toast',
				'position-fixed',
				'p-4',
				'rounded',
				'text-inverse',
				'font-size-small',
				'bg-black',
				!toast.display && 'hide-toast'
			])}
			aria-hidden="true"
			data-testid="toast"
		>
			<span>{toast.message}</span>
		</div>
	);
};

export default Toast;
